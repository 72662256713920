<template>

  <div class="d-flex flex-column align-items-center">

    <b-card style="min-width: 480px; min-height: 128px;" class="bg-white w-50 mt-16 mx-auto">

      <div v-if="!stripe" class="d-flex justify-content-center mb-8 mt-8">
        <b-spinner label="Loading..."></b-spinner>
      </div>

      <form id="payment-form">
        <div id="payment-element">

          <!--Stripe.js injects the Payment Element-->
        </div>

        <b-button class="mt-16" v-if="local_order && local_order.stripe_client_secret" variant="primary" block :disabled="spinning" @click.prevent="handleSubmit">
          <b-spinner v-if="spinning" small type="grow"></b-spinner>
          {{$t('SHOP_ORDER.PAY_NOW')}}
        </b-button>

      </form>

    </b-card>

    <PaymentStatusComponent
      v-if="local_order && interval_id"
      :order="local_order"
    />

  </div>
</template>

<script>
import axios from 'axios';
import PaymentComplete from '@/view/components/PaymentComplete.vue';
import PaymentOrderDetails from '@/view/components/PaymentOrderDetails.vue';
import PendingPaymentInfo from '@/view/components/PendingPaymentInfo.vue';
import PaymentStatusComponent from '@/view/pages/members/payment/PaymentStatusComponent.vue';
import { StripeElementCard } from '@vue-stripe/vue-stripe';

import { setKeyValue, getKeyValue } from '@/core/services/simpleStore';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { is_mobile } from '@/core/services/utils';
import { get_domain_url, get_base_url, downloadWithAxios } from '@/core/services/fileDownload';

export default {
  name: 'StripePaymentComponent',
  props: ['order'],
  emits: ['update', 'start_polling'],
  mixins: [ toasts ],
  components: {
    StripeElementCard,
    PaymentComplete,
    PaymentOrderDetails,
    PendingPaymentInfo,
    PaymentStatusComponent
  },
  computed: {
    min_width() {
      return {
        'min-width': is_mobile()  ? '460px' : '640px'
      };
    },

    is_mobile() {
      return is_mobile();
    },

    callback_url() {
      return get_domain_url() + `/return-all-payment/${this.local_order.token}/${this.local_order.shop_order_id}`
    }

  },
  data() {
    return {
      url: '',

      local_order: null,

      stripe: null,

      spinning: false,

      interval_id: null,

      elementsOptions: {
        elementAppearence: { theme: "stripe" },
        /*elementClientSecret: async () => {
          return await retrieveClientSecret();
        },*/
        clientSecret: '',
        elementClientSecret: null
      },
      confirmParams: {
        return_url: 'http://localhost:8080/success', // success url
      },

    };
  },

  mounted() {

    if (this.order) {
      this.local_order = { ...this.order };
    }

    if (!is_mobile()) {

    }

    this.init_stripe();

    this.$emit('start_polling');
  },
  watch: {
    order: {
      deep: true, // check properties of the form, not just the form reference (example.name for example)
      handler(val) {
        this.local_order = { ...this.order };
      }
    },
  },
  methods: {
    async handleSubmit(e) {
      try {
        this.spinning = true;

        if (e) {
          e.preventDefault();
        }

        this.url = window.location.origin ? window.location.origin : '';
        this.url = `${this.url}/return-all-payment/${this.local_order.token}/${this.local_order.shop_order_id}`;

        const { error } = await this.stripe.confirmPayment({
          elements: this.elements,
          confirmParams: {
            // Make sure to change this to yo ur payment completion page
            return_url: this.url//"http://localhost:4242/checkout.html",
          },
        });


        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (error.type === "card_error" || error.type === "validation_error") {
          console.error('validation error');
          this.spinning = false;
          return;
        }

        this.$emit('start_polling');
      }
      catch (err) {
        console.error('handleSubmit error', err);
      }

      this.spinning = false;
    },


    async init_stripe() {
      try {
        let divScripts = document.getElementById('load-script');
        let newScript = document.createElement('script');
        newScript.src = 'https://js.stripe.com/v3/';
        divScripts.appendChild(newScript);

        if (!this.local_order.stripe_client_secret) {
          await this.request_stripe_payment_intent();
        }

        while (!this.local_order.stripe_pk) {
          console.error('missing PK for stripe');

          await new Promise((resolve) => setTimeout(resolve, 1000));
        }

        console.log('checking stripe', window.Stripe);
        /// wait for Stripe to load
        while (window.Stripe === null || window.Stripe === undefined) {
          console.log('stripe undefined');
          await new Promise(r => setTimeout(r, 500));
        }

        console.log('stripe ok');

        this.stripe = window.Stripe(this.local_order.stripe_pk);

        this.$nextTick(()=>{
          this.create_stripe_frame();
        });
      }
      catch (err) {
        console.error('init_stripe error', err);
      }
    },

    async request_stripe_payment_intent() {
      try {
        const res = await axios.post(`/stripe/paymentintent/${this.local_order.token}/${this.local_order.shop_order_id}`);

        if (res.status === 201) {
          this.$emit('update', res.data);
          // payment intent created, proceed to show stripe payment inputs
        }
      }
      catch (err) {
        console.error('request_stripe_payment_intent error', err);
      }
    },

    create_stripe_frame() {

      /// find #payment-element

      const html_element = document.getElementById('payment-element');

      if (!html_element) {
        const that = this;

        setTimeout(()=>{
          that.create_stripe_frame();
        }, 1000);

        console.log('stripe element not found, trying again...');

        return;
      }

      if (!this.local_order.stripe_client_secret) {
        console.log('no stripe client secret, trying again...');

        const that = this;

        setTimeout(()=>{
          that.create_stripe_frame();
        }, 1000);

        return;
      }

      const appearance = {
        theme: 'stripe',
      };

      this.elements = this.stripe.elements({ appearance, clientSecret: this.local_order.stripe_client_secret });

      this.confirmParams.return_url = this.url;

      this.proceed = true;

      this.elementsOptions.clientSecret = this.local_order.stripe_client_secret;
      this.elementsOptions.elementClientSecret = this.local_order.stripe_client_secret;

      const paymentElementOptions = {
        layout: "tabs",
      };

      const paymentElement = this.elements.create("payment", paymentElementOptions);
      paymentElement.mount("#payment-element");
    },


  }
};
</script>
